// Updated CreditScoringFeaturesMenu.vue that fits your project structure
<template>
  <v-flex xs12>
    <v-card class="elevation-2 mb-3">
      <v-card-text class="pa-2">
        <v-layout row wrap align-center justify-center>
          <v-tooltip bottom v-for="(feature, index) in filteredFeatures" :key="index">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                :color="isActiveRoute(feature.route) ? 'primary' : ''"
                @click="navigateTo(feature.route)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>{{ feature.icon }}</v-icon>
                {{ feature.name }}
              </v-btn>
            </template>
            <span>{{ feature.description }}</span>
          </v-tooltip>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import storageService from '@/services/storage-service'

export default {
  name: 'CreditScoringFeaturesMenu',
  
  data() {
    return {
      user: null,
      features: [
        {
          name: 'Dashboard',
          icon: 'dashboard',
          route: '',
          description: 'View and analyze credit scoring data',
          requiredPrivilege: null // No specific privilege required for dashboard
        },
        {
          name: 'Upload Data',
          icon: 'cloud_upload',
          route: 'upload-data',
          description: 'Upload loan information and risk data files',
          requiredPrivilege: 'Upload Data'
        },
        {
          name: 'Mass Report',
          icon: 'library_books',
          route: 'mass-report',
          description: 'Generate reports for multiple loans',
          requiredPrivilege: 'Generate Reports'
        },
        {
          name: 'View Reports',
          icon: 'list',
          route: 'view-reports',
          description: 'Access and manage generated reports',
          requiredPrivilege: 'Generate Reports'
        },
        {
          name: 'Risk Rating Settings',
          icon: 'settings',
          route: 'risk-rating-settings',
          description: 'Configure risk rating grades and score bands',
          requiredPrivilege: 'Configure Risk Grades'
        }
      ]
    };
  },

  computed: {
    // Filter features based on user privileges
    filteredFeatures() {
      if (!this.user || !this.user.privileges) {
        return []; // No features shown if no user or privileges
      }

      return this.features.filter(feature => {
        // If no specific privilege required, show the feature
        if (!feature.requiredPrivilege) {
          return true;
        }
        
        // Check if user has the required privilege and it's activated
        return this.hasPrivilege(feature.requiredPrivilege);
      });
    }
  },

  created() {
    this.user = storageService.getItem('user');
  },
  
  methods: {
    // Check if user has the specified privilege and it's activated
    hasPrivilege(privilegeName) {
      if (!this.user || !this.user.privileges) {
        return false;
      }
      
      const privilege = this.user.privileges.find(p => 
        p.privilege_app === 'Credit Scoring Model' && 
        p.privilege_name === privilegeName &&
        p.is_activated === true
      );
      
      return !!privilege;
    },

    navigateTo(route) {
      const path = `/app/credit-scoring/${route}`;
      
      // Before navigation, verify that user has necessary privileges
      const feature = this.features.find(f => f.route === route);
      
      if (feature && feature.requiredPrivilege && !this.hasPrivilege(feature.requiredPrivilege)) {
        // User doesn't have permission - show error message
        this.$store.dispatch('showSnackbar', {
          text: `Access denied: You don't have the required "${feature.requiredPrivilege}" privilege`,
          color: 'error'
        });
        return;
      }
      
      this.$router.push(path).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation error:', err);
        }
      });
    },
    
    isActiveRoute(route) {
      const currentPath = this.$route.path;
      const targetPath = `/app/credit-scoring/${route}`;
      
      // For the dashboard (empty route), do a strict comparison
      if (route === '') {
        return currentPath === '/app/credit-scoring' || currentPath === '/app/credit-scoring/';
      }
      
      // For other routes, check if the current path includes the target path
      return currentPath.includes(targetPath);
    }
  }
};
</script>